import React, { useState } from "react";
import { scrollX } from "./Utility";

function Titles({
  version = "default",
  title = null,
  tagline = null,
  className = "title",
  content,
  setVideo,
}) {
  const elID = (version, className) => {
    return version + className;
  };

  const [iconSwitch, setIconSwitch] = useState(false);

  const iconSwitchHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    !iconSwitch ? setIconSwitch(true) : setIconSwitch(false);
  };

  const handleVidClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    setVideo(url);
    scrollX(e, "#cta-7");
  };

  const renderSwitch = (version) => {
    switch (version) {
      case "h1":
        return (
          <>
            <h1>{title}</h1>
            <h3>{tagline}</h3>

            <span id="scrollMark" style={{ width: "0", height: "0" }}></span>

            <div className="vid-click-cont no-small">
              <a href="#cta-1" onClick={iconSwitchHandler}>
                <img
                  loading="lazy"
                  src="/img/youtube_icon.svg"
                  className="header-icon"
                  alt="youtube icon play button"
                />
              </a>
              {iconSwitch !== false ? (
                <ul className="popMenu e-z">
                  <li>
                    <a
                      href="#cta-7"
                      onClick={(e) => handleVidClick(e, "AN6cFJfNWMc")}
                    >
                      <b>Watch:</b> Full Version of This Video
                    </a>
                  </li>
                  <li>
                    <a
                      href="#cta-7"
                      onClick={(e) => handleVidClick(e, "3_1Ue8b_PGo")}
                    >
                      <b>Watch:</b> Installer Testimonial
                    </a>
                  </li>
                  <li>
                    <a
                      href="#cta-7"
                      onClick={(e) => handleVidClick(e, "_v8vizQXwss")}
                    >
                      <b>Watch:</b> Heats at -24 Degrees
                    </a>
                  </li>
                  <li>
                    <a
                      href="#cta-7"
                      onClick={(e) => handleVidClick(e, "w6Z8_dpXFM4")}
                    >
                      <b>Watch:</b> Quick Connect
                      <span className="reg-mark">&reg;</span> Installation
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </>
        );

      case "h2-cta1":
        return (
          <>
            <h2>{title}</h2>

            <h4>{tagline}</h4>
          </>
        );
      case "h3-cta2":
        return (
          <>
            <h3 style={{ color: "#F0F4F7" }}>{title}</h3>

            <h4 style={{ color: "#F0F4F7" }}>{tagline}</h4>
          </>
        );

      case "h2-cta1-w-cont":
        return (
          <>
            <h2>{title}</h2>

            <h4>{tagline}</h4>

            <p style={{ textAlign: "center", display: "block" }}>{content}</p>
          </>
        );
      default:
        return (
          <>
            <h2>{title}</h2>

            <h4>{tagline}</h4>
          </>
        );
    }
  };

  return (
    <div className={className} id={elID(version, className)}>
      {renderSwitch(version)}
    </div>
  );
}

export default Titles;
