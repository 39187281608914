import React from "react";
import { scrollX } from "./Utility";

function Footer() {
  return (
    <>
      <div className="foot">
        <ul className="footSocNet">
          <li>
            <a
              href="https://www.facebook.com/MRCOOLcomfort"
              target="_blank"
              title="MRCOOL on Facebook"
              rel="noopener noreferrer"
            >
              <img
                id="fb_icon"
                className="soc-icon"
                loading="lazy"
                src="/img/fb_icon.svg"
                alt="MRCOOL on Facebook"
              />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/MRCOOLcomfort/"
              target="_blank"
              title="MRCOOL on Twitter"
              rel="noopener noreferrer"
            >
              <img
                id="tweet_icon"
                className=" soc-icon"
                loading="lazy"
                src="/img/tweet_icon.svg"
                alt="MRCOOL on Twitter"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/user/MrCoolAirConditioner"
              target="_blank"
              title="MRCOOL on Youtube"
              rel="noopener noreferrer"
            >
              <img
                id="youtube_icon"
                className="soc-icon"
                loading="lazy"
                src="/img/youtube_icon.svg"
                alt="MRCOOL on YouTube"
              />
            </a>
          </li>
        </ul>
        <ul className="footContact">
          <li>SUPPORT:</li>
          <li>
            <a
              href="https://mrcool.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email Us Here{" "}
              <img
                className="inline-svg"
                loading="lazy"
                src="/img/goto.svg"
                alt="goto email us"
              />
            </a>
          </li>
          <li>
            <a href="tel:2703660457" target="_blank" rel="noopener noreferrer">
              Call Us At: 270-366-0457
            </a>
          </li>
        </ul>
        <ul className="footCopyright">
          <li>
            Copyright &copy; <span id="year">{new Date().getFullYear()}</span>{" "}
            MRCOOL<span className="reg-mark">&reg;</span>&nbsp;&nbsp;&nbsp; All
            Rights Reserved
          </li>
          <li id="privacy-policy" className="" style={{ float: "left" }}>
            <a title="Privacy Policy" href="https://mrcool.com/privacy-policy/">
              Privacy&nbsp;Policy
            </a>
          </li>
          <li id="terms-conditions" className="">
            <a
              title="Terms and Conditions"
              href="https://mrcool.com/terms-and-conditions/"
            >
              Terms&nbsp;&amp;&nbsp;Conditions
            </a>
          </li>
        </ul>
      </div>

      <img
        loading="lazy"
        src="/img/to-the-top_btn.svg"
        alt="scoll to the top button"
        aria-label="scoll to the top button"
        className="svgBtn"
        id="toTopBtn"
        onClick={(e) => scrollX(e, "#scrollTarget")}
      />
    </>
  );
}

export default Footer;
