export const scrollX = (e, targetID) => {
  /** set target from input ID */
  var scrollTarget = document.querySelector(targetID);
  /** prevent a-tag href from firing on click */
  e.preventDefault();
  /** prevent "bubbling" to parent */
  e.stopPropagation();
  /** use JS default scroll into view with smooth setting */
  scrollTarget.scrollIntoView({
    behavior: "smooth",
  });
};
