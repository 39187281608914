import React, { useRef } from "react";

function Banner(props) {
  const ext_1 = ".jpg";
  const ext_2 = ".webp";
  const vidRef = useRef(null);
  const vidToggle = () => {
    vidRef.current.paused ? vidRef.current.play() : vidRef.current.pause();
  };

  /** REMOVE LOADER SVG */
  /** end svg loader animation 2 seconds after banner bg video loads, rather than on window load and waiting for 360 images to preload  */
  const endLoading = () => {
    var loaderSVG = document.getElementById("loaderSVG");
    setTimeout(function () {
      /** check that it exists after component re-render */
      if (loaderSVG) {
        /** original */
        loaderSVG.remove();
        /** IE sucks version */
        //loaderSVG.parentNode.removeChild(loaderSVG);
        /** => added "remove" IE SHIM to main.js */
      }
    }, 1000);
  };
  /** END: REMOVE LOADING SVG */

  let poster;
  if (props.mediaType !== "no-webp" && props.mediaType !== null) {
    poster = props.bg + ext_2;
  } else if (props.mediaType !== null) {
    poster = props.bg + ext_1;
  }

  /** if viewport is not large (> 720px) per passed props then display image banner, otherwise display video banner */
  if (props.mediaSize !== "lg") {
    return (
      <div className="banner">
        <span id="scrollTarget" style={{ width: "0", height: "0" }}></span>

        <img
          src={poster}
          preload={"true"}
          onLoad={endLoading}
          alt="Robot saws car in half video still header background"
        ></img>

        <div className="overlay">
          <picture>
            <source srcSet={props.overlay + ext_2} type="image/webp"></source>
            <source srcSet={props.overlay + ext_1} type="image/jpeg"></source>
            <img
              className="header-overlay"
              id="header-overlay"
              src={props.overlay + ext_1}
              onClick={vidToggle}
              alt=""
              title=""
            />
          </picture>
        </div>
      </div>
    );
    /** media size large then display video banner */
  } else {
    return (
      <div className="banner">
        <span id="scrollTarget" style={{ width: "0", height: "0" }}></span>

        <video
          ref={vidRef}
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          id="mc-uni-vid"
          poster={poster}
          preload={"true"}
          onCanPlayThrough={endLoading}
        >
          <source src={props.vid + ".mp4"} type="video/mp4"></source>
          <source src={props.vid + ".webm"} type="video/webm"></source>
        </video>

        <div className="overlay">
          <picture>
            <source srcSet={props.overlay + ext_2} type="image/webp"></source>
            <source srcSet={props.overlay + ext_1} type="image/jpeg"></source>
            <img
              className="header-overlay"
              id="header-overlay"
              src={props.overlay + ext_1}
              onClick={vidToggle}
              alt=""
              title=""
            />
          </picture>
        </div>
      </div>
    );
  }
}

export default Banner;
