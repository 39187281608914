import React from "react";
import Navbar from "./Navbar";
import Titles from "./Titles";
import Banner from "./Banner";

function Header({ setVideo, mediaSize, mediaType }) {
  let bg = "";
  let overlay = "";
  let vid = "";
  if (mediaSize === "sm") {
    bg = "/img/header-img-bg-sm";
    overlay = "/img/hex-grid-2-sm";
    vid = "/vid/mc-uni-clip-03-sm";
  } else {
    bg = "/img/header-img-bg";
    overlay = "/img/hex-grid-2";
    vid = "/vid/mc-uni-clip-03";
  }

  return (
    <>
      <header>
        <Navbar setVideo={setVideo} />
        <Banner
          bg={bg}
          overlay={overlay}
          vid={vid}
          mediaSize={mediaSize}
          mediaType={mediaType}
        />
        <Titles
          version="h1"
          title={[
            "MRCOOL Universal",
            <span key="reg2" className="reg-mark">
              &reg;
            </span>,
          ]}
          tagline="The Future of Heating & Cooling"
          className="title"
          setVideo={setVideo}
        />
      </header>
    </>
  );
}

export default Header;
