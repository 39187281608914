import React, { useState } from "react";
// import MenuItem from "./MenuItem";
import { scrollX } from "./Utility";

function Navbar({ setVideo }) {
  const [menuToggle, setMenuToggle] = useState(false);

  const toggleHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    !menuToggle ? setMenuToggle(true) : setMenuToggle(false);
  };

  const handleVidClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    setVideo(url);
    scrollX(e, "#cta-7");
    !menuToggle ? setMenuToggle(true) : setMenuToggle(false);
  };
  return (
    <nav id="navbar" className="navbar">
      <div className="nav-icon-cont">
        <a
          className="menuLink"
          href="https://mrcool.com/mrcool-universal-series-dc-inverter-heat-pump-air-conditioner-split-system/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src="/img/mrcool_icon.svg"
            className="header-icon"
            alt="go to the features icon button"
          />
        </a>
        <a
          className="menuLink"
          href="#cta-2"
          onClick={(e) => scrollX(e, "#cta-6")}
        >
          <img
            loading="lazy"
            src="/img/buynow_icon.svg"
            className="header-icon"
            id="shop-now"
            alt="go to the features icon button"
          />
        </a>

        <a className="menuLink" href="#cta-1" onClick={toggleHandler}>
          <img
            loading="lazy"
            src="/img/menu_icon.svg"
            className="header-icon"
            alt="go to the 360 view icon button"
          />
        </a>
        {menuToggle ? (
          <ul
            id="dropMenu"
            className="popMenu e-z"
            // onMouseLeave={() => setMenuToggle(false)}
          >
            <li>
              <a
                className="menuLink"
                href="https://mrcool.com/mrcool-universal-series-dc-inverter-heat-pump-air-conditioner-split-system/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Go To:</b> Universal at MRCOOL.com
              </a>
            </li>
            <li>
              <a
                className="menuLink"
                href="#cta-5"
                onClick={(e) => {
                  scrollX(e, "#cta-5");
                  toggleHandler(e);
                }}
              >
                <b>Go To:</b> Features
              </a>
            </li>
            <li>
              <a
                className="menuLink"
                href="#cta-2"
                onClick={(e) => {
                  scrollX(e, "#cta-6");
                  toggleHandler(e);
                }}
              >
                <b>Go To:</b> Purchasing Options
              </a>
            </li>
            <li>
              <a
                className="menuLink"
                href="#cta-5"
                onClick={(e) => {
                  scrollX(e, "#cta-9");
                  toggleHandler(e);
                }}
              >
                <b>Go To:</b> Documentation
              </a>
            </li>
            <li>
              <a
                className="menuLink"
                href="#cta-1"
                onClick={(e) => {
                  scrollX(e, "#cta-1");
                  toggleHandler(e);
                }}
              >
                <b>Go To:</b> 360 Spin Image
              </a>
            </li>
            <li>
              <a
                href="#cta-7"
                onClick={(e) => handleVidClick(e, "AN6cFJfNWMc")}
              >
                <b>Watch:</b> Full Version of This Video
              </a>
            </li>
            <li>
              <a
                href="#cta-7"
                onClick={(e) => handleVidClick(e, "3_1Ue8b_PGo")}
              >
                <b>Watch:</b> Installer Testimonial
              </a>
            </li>
            <li>
              <a
                href="#cta-7"
                onClick={(e) => handleVidClick(e, "_v8vizQXwss")}
              >
                <b>Watch:</b> Heats at -24 Degrees
              </a>
            </li>
            <li>
              <a
                href="#cta-7"
                onClick={(e) => handleVidClick(e, "w6Z8_dpXFM4")}
              >
                <b>Watch:</b> Quick Connect
                <span className="reg-mark">&reg;</span> Installation
              </a>
            </li>
          </ul>
        ) : null}
      </div>
    </nav>
  );
}

export default Navbar;
