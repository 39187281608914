import React, { lazy, Suspense, useState } from "react";
import Titles from "./Titles";
const Video = lazy(() => import("./Video"));
const CTA = lazy(() => import("./CTA"));
const Reveal2 = lazy(() => import("./Reveal2"));
const RevealToo = lazy(() => import("./RevealToo"));
const DoxSxn = lazy(() => import("./DoxSxn"));
const ImgRotate = lazy(() => import("./ImgRotate"));
const IconDiv = lazy(() => import("./IconDiv"));

function Sections({
  className,
  id,
  title,
  tagline,
  content,
  img,
  imported,
  videoID,
  playerPlay,
  mediaSize,
  mediaType,
}) {
  const ext_1 = ".jpg";
  const ext_2 = ".webp";
  // const ext_3 = ".png";
  // const ext_4 = ".svg";

  const [Rotating, setRotating] = useState(null);

  const selectRotate = (e, choice) => {
    e.preventDefault();
    e.stopPropagation();

    choice !== "AH" ? setRotating("C") : setRotating("AH");
  };

  return (
    <section className={className} id={id}>
      {/* Set Titles */}
      {title === false ? null : id === "cta-9" ? (
        <Titles
          version="h2-cta1-w-cont"
          title={title}
          tagline={tagline}
          content={content}
        />
      ) : id === "cta-1" && Rotating !== null ? (
        <>
          <Titles version="h2-cta1" title={title} tagline={tagline} />
          <h5 style={{ textAlign: "center" }}>
            <button
              className="spin-select"
              href="#"
              onClick={(e) => {
                selectRotate(e, "AH");
              }}
            >
              {" "}
              Air Handler{" "}
            </button>

            <button
              className="spin-select"
              href="#"
              onClick={(e) => {
                selectRotate(e, "C");
              }}
            >
              {" "}
              Condenser{" "}
            </button>
          </h5>
        </>
      ) : className === "cta-1" ? (
        <Titles version="h2-cta1" title={title} tagline={tagline} />
      ) : (
        <Titles version="h3-cta2" title={title} tagline={tagline} />
      )}

      {/* Set content  */}
      {id === "cta-2" ||
      id === "cta-6" ||
      id === "cta-10" ||
      id === "cta-11" ? (
        <Suspense fallback={<p>Loading...</p>}>
          <CTA content={content} />
        </Suspense>
      ) : null}

      {id === "eco-rebates-bar" ? <div id="ecorebates1"></div> : null}
      {id === "eco-rebates-bar2" ? <div id="ecorebates2"></div> : null}
      {id === "eco-rebates-bar3" ? <div id="ecorebates3"></div> : null}

      {id === "cta-1" ? (
        <>
          <Suspense fallback={<p>Loading...</p>}>
            {Rotating === null ? (
              <div
                className="dml-360-div"
                onClick={(e) => {
                  selectRotate(e, "C");
                }}
              >
                <h5 style={{ textAlign: "center", margin: "50% 0" }}>
                  <button
                    className="spin-select"
                    href="#"
                    onClick={(e) => {
                      selectRotate(e, "AH");
                    }}
                  >
                    {" "}
                    Air Handler{" "}
                  </button>

                  <button
                    className="spin-select"
                    href="#"
                    onClick={(e) => {
                      selectRotate(e, "C");
                    }}
                  >
                    {" "}
                    Condenser{" "}
                  </button>
                </h5>
                <img
                  loading="lazy"
                  src="/img/360_SPIN.svg"
                  id="spin-overlay-img"
                  alt="DML 360 Slider - give it a spin"
                ></img>
              </div>
            ) : null}
            {Rotating === "C" ? (
              <ImgRotate
                mediaType={mediaType}
                numX={24}
                preImg="/img/uni-c-360/uni-c-"
              />
            ) : null}
            {Rotating === "AH" ? (
              <ImgRotate
                mediaType={mediaType}
                numX={25}
                preImg="/img/uni-ah-360/uni-ah-"
              />
            ) : null}
            <IconDiv />
          </Suspense>
        </>
      ) : null}
      {id === "cta-3" ? (
        <>
          <picture>
            <source srcSet={img.src + ext_2} type="image/webp"></source>
            <source srcSet={img.src + ext_1} type="image/jpeg"></source>
            <img
              className={img.className}
              id={img.id}
              src={img.src + ext_1}
              alt={img.alt}
              title={img.title}
            />
          </picture>

          <div className="leftOver">
            <h5>{content[0]}</h5>
            <ul style={{ listStyle: "disc" }}>
              {content[1].map((li) => (
                <li key={li.id} id={li.id}>
                  {li.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="centerAfter">
            <p>{content[2]}</p>
          </div>
        </>
      ) : null}
      {id === "cta-5" ? (
        <Suspense fallback={<p>Loading...</p>}>
          <Reveal2 />
        </Suspense>
      ) : null}
      {id === "cta-12" ? (
        <Suspense fallback={<p>Loading...</p>}>
          <RevealToo />
        </Suspense>
      ) : null}
      {id === "cta-8" ? (
        <>
          <img
            className={img.className}
            id={img.id}
            loading="lazy"
            src={img.src}
            alt={img.alt}
            title={img.title}
          />
        </>
      ) : null}

      {id === "cta-4" ? (
        <>
          <picture>
            <source srcSet={img.src + ext_2} type="image/webp"></source>
            <source srcSet={img.src + ext_1} type="image/jpeg"></source>
            <img
              className={img.className}
              id={img.id}
              loading="lazy"
              src={img.src + ext_1}
              alt={img.alt}
              title={img.title}
            />
          </picture>
        </>
      ) : null}
      {id === "cta-7" ? (
        <Suspense fallback={<p>Loading...</p>}>
          <Video videoID={videoID} playerPlay={playerPlay} />
        </Suspense>
      ) : null}

      {id === "cta-9" ? (
        <Suspense fallback={<p>Loading...</p>}>
          <DoxSxn mediaType={mediaType} />
        </Suspense>
      ) : null}
    </section>
  );
}

export default Sections;
