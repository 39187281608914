import React, { useState, useEffect } from "react";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import Append from "./Append";

function App() {
  const [videoID, setVideoID] = useState("3_1Ue8b_PGo");
  const [playerPlay, setPlayerPlay] = useState(false);
  const [mediaSize, setMediaSize] = useState("lg");
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    /** look for media query active in css that matches < 720px */
    const mm = window.matchMedia("(max-width:720px)");
    /** if matches set to size small - if not then size large */
    if (mm.matches) {
      setMediaSize("sm");
    } else {
      setMediaSize("lg");
    }

    /** check for webp compatibility before loading then decide webp or fallback */
    async function supportsWebp() {
      // eslint-disable-next-line no-restricted-globals
      if (!self.createImageBitmap) return false;
      /** create test webp data - if unable = false */
      const webpData =
        "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
      const blob = await fetch(webpData).then((r) => r.blob());
      return createImageBitmap(blob).then(
        () => true,
        () => false,
      );
    }

    (async () => {
      /** wait and see if blob was rendered  */
      if (await supportsWebp()) {
        /** supports webp - preload with webp ext */
        setMediaType("webp");
      } /** if blob wasn't rendered */ else {
        /** does not supports webp - preload with jpg ext */
        setMediaType("no-webp");
      }
    })();
    /** note cannot set 'window' as a dependency here because will not refire based on something outside of the Apps scope */
  }, []);

  function setVideo(videoURL) {
    setVideoID(videoURL);
    setPlayerPlay(true);
  }

  return (
    <>
      <Header setVideo={setVideo} mediaSize={mediaSize} mediaType={mediaType} />
      <Main
        videoID={videoID}
        playerPlay={playerPlay}
        mediaSize={mediaSize}
        mediaType={mediaType}
      />
      <Footer />
      <Append />
    </>
  );
}

export default App;
