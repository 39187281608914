import React from "react";
import Sections from "./Sections";

function Main({ videoID, playerPlay, mediaSize, mediaType }) {
  /** img src extension added in picture element in order to use webp */
  let imgMedia_1 = "";
  let imgMedia_2 = "";
  if (mediaSize === "sm") {
    imgMedia_1 = "/img/mc-uni-sxn-01-sm";
    imgMedia_2 = "/img/mc-uni-sxn-04-sm";
  } else {
    imgMedia_1 = "/img/mc-uni-sxn-01";
    imgMedia_2 = "/img/mc-uni-sxn-04";
  }

  const sectionAttrs = [
    {
      className: "cta-2",
      id: "cta-2",
      title: ["Purchase the ", <b key="bold1">Future</b>, " - Today!"],
      tagline: ["Enter Your Space - Find Your Deal"],
      content: 1050,
      img: "",
      imported: "",
    },
    {
      className: "cta-1",
      id: "cta-5",
      title: "Advanced DC Inverter Technology",
      tagline: "NOT Your Ordinary Heat Pump",
      content: false,
      img: {
        className: "sxn-img",
        id: "cta-5-img",
        src: "/img/uni-c-reveal-02.png",
        alt: "MRCOOL Universal Condenser sliding reveal of inside",
        title: "MRCOOL Universal Condenser sliding reveal of inside",
      },
      imported: "",
    },
    {
      className: "cta-2",
      id: "eco-rebates-bar",
      title: false,
      tagline: false,
      content: false,
      img: false,
      imported: false,
    },

    {
      className: "cta-1",
      id: "cta-3",
      title: [
        "What Is The MRCOOL Universal",
        <span key="reg2" className="reg-mark">
          ®
        </span>,
        "?",
      ],
      tagline: "A Heating & Cooling Machine From The Future*",
      content: [
        "The MRCOOL Universal is...",
        [
          {
            id: "cta-3-content-1",
            text: "A High Performance Central Heating & Air Solution",
          },
          {
            id: "cta-3-content-2",
            text: "The Next Generation DC Inverter Technology",
          },
          {
            id: "cta-3-content-3",
            text: "A Heat Pump that Can Replace a Furnace & Save Money",
          },
          {
            id: "cta-3-content-4",
            text: "An Air Conditioner that Works With Your Furnace to Save Money",
          },
          {
            id: "cta-3-content-5",
            text: "Quiet, Compact and Easy to Install",
          },
          {
            id: "cta-3-content-6",
            text: "Compatible with our Patented Quick Connect® Technology",
          },
        ],
        "* It may be a future where robots serve you rubbing alcohol martinis and cut your old car in half with a chainsaw, but that future can be comfortable, smart and easy starting now.",
      ],
      img: {
        className: "sxn-img",
        id: "cta-3-img",
        src: imgMedia_1,
        alt: "Robots love the MRCOOL Universal DC Inverter",
        title: "Robots love the MRCOOL Universal DC Inverter",
      },
      imported: "",
    },
    {
      className: "cta-2",
      id: "cta-6",
      title: ["Purchase the ", <b key="bold1">Future</b>, " - Today!"],
      tagline: ["Enter Your Space - Find Your Deal"],
      content: 1450,
      img: "",
      imported: "",
    },
    {
      className: "cta-1",
      id: "cta-1",
      title: "The Future is Cool",
      tagline: [
        "Give the MRCOOL",
        <span key="reg1" className="reg-mark">
          ®
        </span>,
        " Universal",
        <span key="reg2" className="reg-mark">
          ®
        </span>,
        "A Spin",
      ],
      content: "",
      img: "",
      imported: "",
    },
    {
      className: "cta-2",
      id: "cta-4",
      title: false,
      tagline: false,
      content: false,
      img: {
        className: "sxn-img",
        id: "cta-4-img",
        src: imgMedia_2,
        alt: "MRCOOL Universal DC Inverter Reliability backed by warranty",
        title: "MRCOOL Universal DC Inverter Reliability backed by warranty",
      },
      imported: "",
    },
    {
      className: "cta-2",
      id: "cta-10",
      title: ["Purchase the ", <b key="bold1">Future</b>, " - Today!"],
      tagline: ["Enter Your Space - Find Your Deal"],
      content: 1050,
      img: "",
      imported: "",
    },
    {
      className: "cta-1",
      id: "cta-7",
      title: false,
      tagline: false,
      content: false,
      img: false,
      imported: "",
    },
    {
      className: "cta-1",
      id: "cta-12",
      title: "Advanced DC Inverter Technology",
      tagline: "NOT Your Ordinary Heat Pump",
      content: false,
      img: {
        className: "sxn-img",
        id: "cta-5-img",
        src: "/img/uni-c-reveal-04.png",
        alt: "MRCOOL Universal Condenser sliding reveal of inside",
        title: "MRCOOL Universal Condenser sliding reveal of inside",
      },
      imported: "",
    },
    {
      className: "cta-2",
      id: "cta-8",
      title: false,
      tagline: false,
      content: false,
      img: {
        className: "sxn-img",
        id: "cta-7-img",
        src: "/img/mc-uni-infographic-02.svg",
        alt: "MRCOOL Universal DC Inverter Temperature Performance Infographic",
        title:
          "MRCOOL Universal DC Inverter Temperature Performance Infographic",
      },
      imported: "",
    },
    {
      className: "cta-1",
      id: "cta-9",
      title: "Need More Info?",
      tagline: "Check Out Our Docs",
      content: "Robots Love Docs",
      img: "",
      imported: "",
    },
    {
      className: "cta-2",
      id: "cta-11",
      title: ["Purchase the ", <b key="bold1">Future</b>, " - Today!"],
      tagline: ["Enter Your Space - Find Your Deal"],
      content: 1450,
      img: "",
      imported: "",
    },
  ];

  return (
    <main>
      {sectionAttrs.map((sectionAttr) => (
        <Sections
          key={sectionAttr.id}
          {...sectionAttr}
          videoID={videoID}
          playerPlay={playerPlay}
          mediaSize={mediaSize}
          mediaType={mediaType}
        />
      ))}
    </main>
  );
}

export default Main;
