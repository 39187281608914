import React, { useEffect } from "react";

function Append() {
  /** add ecorebates script to body end and give enough time for loaderSVG to load, so not orphaned before removing on window load...? */
  useEffect(() => {
    const script_eco = document.createElement("script");
    const script_main = document.createElement("script");

    script_eco.src = "/js/ecorebates.js";
    script_main.src = "/js/main.js";

    script_eco.async = true;
    script_main.async = true;
    script_eco.defer = true;
    script_main.defer = true;

    document.body.appendChild(script_eco);
    document.body.appendChild(script_main);

    return () => {
      document.body.removeChild(script_eco);
      document.body.removeChild(script_main);
    };
  }, []);

  return (
    <div
      className="loaderSVG"
      id="loaderSVG"
      style={{
        backgroundColor: "#f0f4f7",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: "100",
        width: "100%",
        height: "100%",
      }}
    >
      <object
        type="image/svg+xml"
        data="/img/loader.svg"
        aria-label="loading logo animation"
        style={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "101",
        }}
      ></object>
    </div>
  );
}

export default Append;
